import Vue from 'vue'
import VueRouter from 'vue-router'

import HelloWorld from '../components/HelloWorld'
import IntroHistory from '../pages/IntroHistory.vue'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    routes: [
        {path: '/', component: HelloWorld},
        {path: '/intro/history', component:IntroHistory}
    ]
})

export default router
