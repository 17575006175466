<template>
    <div>
        <b-img :src="require('../assets/img/history-bg.jpg')" fluid />
        <b-container fluid>
            <b-card class="card-transparent px-4 text-center" title="项目历程">
                <b-card-body class="text-left" >
                    <h5 class="card-text color-app">2015年 冬日启程</h5>
                    <p class="card-text">座谈+入户，同时调研当地学校和教习所。了解阜南县基础教育状况和青少年的生存现状。</p>
                    <h5 class="card-text color-app">2016年 文化育人</h5>
                    <p class="card-text">开展王家坝精神，通过少年精神谱系建设，文化育人</p>
                    <h5 class="card-text color-app">2017年 引进来+走出去</h5>
                    <p class="card-text">失依儿童状况，“走进大学 树立宏愿”，开阔眼界+健康成长。</p>
                    <h5 class="card-text color-app">2018年 校地合作</h5>
                    <p class="card-text">“人文化成 童心济世” 关注双失儿童生存、心理状态，当地双失儿童家庭扶贫政策及其落实。</p>
                    <h5 class="card-text color-app">2019年 心理健康服务</h5>
                    <p class="card-text">建立心理健康屋，线上一对一心理辅导。对政府相关工作人员及在校教师、监护人进行知识普及和培训。</p>
                    <h5 class="card-text color-app">2020年至今</h5>
                    <p class="card-text">签署失依儿童关爱服务工作方案建议，大学生的“生活关怀+学习帮困+情感慰藉+心里疏导”模式，以专业心理咨询师为支撑开展科普讲座、心理咨询、个案数据库等帮扶。</p>
                </b-card-body>
            </b-card>
        </b-container>
    </div>
</template>

<script>
export default {
    name: 'IntroHistory',
    props: {
    }
}
</script>

<style scoped></style>
