<template>
    <div id="navBar">
        <b-navbar toggleable="lg" type="light">
            <b-navbar-brand to="/">
                <img src="../assets/img/logo.png" alt="logo" class="logo" /><b>满天星共济平台</b>
            </b-navbar-brand>
            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav class="ml-auto">
                    <b-nav-item to="/" class="menu-item border-index">首页</b-nav-item>
                    <b-nav-item-dropdown class="menu-item border-intro" text="项目介绍" right>
                        <b-dropdown-item to="/intro/history">项目历程</b-dropdown-item>
                        <b-dropdown-item href="#">活动回顾</b-dropdown-item>
                        <b-dropdown-item href="#">团队介绍</b-dropdown-item>
                    </b-nav-item-dropdown>
                    <b-nav-item-dropdown class="menu-item border-service" text="心理服务" right>
                        <b-dropdown-item href="#">信息填写</b-dropdown-item>
                        <b-dropdown-item href="#">心理评估</b-dropdown-item>
                        <b-dropdown-item href="#">心理咨询</b-dropdown-item>
                        <b-dropdown-item href="#">课程与活动</b-dropdown-item>
                    </b-nav-item-dropdown>
                    <b-nav-item-dropdown class="menu-item border-recruit" text="志愿者招募" right>
                        <b-dropdown-item href="#">招募方式</b-dropdown-item>
                    </b-nav-item-dropdown>
                    <b-nav-item class="menu-item border-contact" to="/">联系我们</b-nav-item>
                    <b-nav-item-dropdown right  v-if="!$store.state.isLogin">
                        <!-- not login, show default user icon -->
                        <template #button-content>
                            <font-awesome-icon icon="fa-regular fa-circle-user" />
                        </template>
                        <b-dropdown-item href="#">注册</b-dropdown-item>
                        <b-dropdown-item href="#">登录</b-dropdown-item>
                    </b-nav-item-dropdown>
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
    </div>
</template>

<script>
export default {
    name: 'NavBar',
    props: {
        msg: String
    }
}
</script>
<style scoped>
.logo {
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 1rem;
}

.border-index {
    border-bottom: 0.3rem solid #E78267;
}

.border-intro {
    border-bottom: 0.3rem solid #E7AC67;
}

.border-service {
    border-bottom: 0.3rem solid #F6E65E;
}

.border-recruit {
    border-bottom: 0.3rem solid #79CFC4;
}

.border-contact {
    border-bottom: 0.3rem solid #7C6FAF;
}

.menu-item {
    margin-right: .5rem;
}

.dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0;
}</style>
