<template>
  <div id="app">
    <b-container fluid class="py-3 bg-app " >
      <div class="text-left text-light">
        <b>电话：</b>0571-82295639 | <b>邮箱：</b>mantianxing@163.com | <b>地址：</b>上海市杨浦区四平路1239号
      </div>
    </b-container>
    <nav-bar/>
    <div>
      <router-view></router-view>
    </div>
    <footer-bar/>
  </div>
</template>

<script>
import NavBar from './components/NavBar.vue'
import FooterBar from './components/FooterBar.vue'

export default {
  name: 'App',
  components: {
    NavBar,
    FooterBar
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.logo {
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 1rem;
}

.bg-app {
  background-color: #e7ac67 !important;
}

.color-app {
  color: #e78267 !important;
}

.card-transparent {
    background-color: rgba(255, 255, 255, 0.75) !important;
    transform: translateY(-4rem);
    min-width: 15rem;
    max-width: 75rem;
}
</style>
