<template>
  <b-container fluid id="footer-div" class="text-left px-4 pt-4 pb-2">
    <b-row>
      <b-col md="2">
        <p class="h3 footer-title">项目介绍</p>
        <ul class="list-unstyled">
          <li><b-link class="text-decoration-none link-dark" to="/intro/history">项目历程</b-link></li>
          <li><b-link class="text-decoration-none link-dark" href="intro_activities.html">活动回顾</b-link></li>
          <li><b-link class="text-decoration-none link-dark" href="intro_team.html">团队介绍</b-link></li>
        </ul>
      </b-col>
      <b-col md="2">
        <p class="h3 footer-title">心理服务</p>
        <ul class="list-unstyled">
          <li>信息填写</li>
          <li>心理评估</li>
          <li><a class="text-decoration-none link-dark" href="psy_counseling.html">心理咨询</a></li>
          <li><a class="text-decoration-none link-dark" href="psy_courses.html">课程与活动</a></li>
        </ul>
      </b-col>
      <b-col md="2">
        <p class="h3 footer-title">志愿者招募</p>
                <ul class="list-unstyled">
                    <li><a class="text-decoration-none link-dark" href="vol_rules.html">招募方式</a></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
      </b-col>
      <b-col md="4">
        
        <p class="h3 footer-title">联系我们</p>
        <ul class="list-unstyled">
                    <li>电话：0571-82295639</li>
                    <li>邮箱：mantianxing@163.com</li>
                    <li>地址：上海市杨浦区四平路1239号</li>
                    <li></li>
                </ul>
      </b-col>
    </b-row>
  </b-container>
</template>


<script>
export default {
  name: 'FooterBar',
  props: {
    msg: String
  }
}
</script>
<style scoped>
#footer-div {
  background: linear-gradient(rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.75)), url(../assets/img/footer-bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.footer-title {
  color: #F08F1E
}

.link-dark {
  color: #212529
}

.link-dark:hover,
.link-dark:focus {
  color: #1a1e21
}</style>
