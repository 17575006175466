<template>
  <div>
    <div class="position-relative ratio" style="--bs-aspect-ratio: 50%;">
      <b-carousel controls indicators>
        <b-carousel-slide caption="" text="" :img-src="image" v-for="(image, index) in images"
          v-bind:key="index"></b-carousel-slide>
      </b-carousel>
      <b-container pt="5" class="text-left h1 position-absolute d-flex align-items-center" mx="5"
        style="top:0;bottom:0;left:0;right:0;">
        <b-container fluid>
          <b-row class="shadowed-text text-white font-weight-bold">
            <b-col md="8">
              以微光照亮微光
            </b-col>
          </b-row>
          <b-row class="shadowed-text text-white font-weight-bold">
            <b-col md="8">
              让孩子心有所依
            </b-col>
          </b-row>
          <b-row><br /></b-row>
          <b-row>
            <b-col style="z-index:999;">
              <b-button size="lg" style="background-color:#E7AC67; color:#000;" :outline="false">加入我们</b-button>
            </b-col>
          </b-row>
        </b-container>
      </b-container>
    </div>
    <b-container class="text-left py-5">
      <b-row>
        <b-col md="12" size="lg">
          <p class="h2">最新资讯</p>
        </b-col>
      </b-row>
    </b-container>
    <section class="text-left text-white bg-app">
      <b-container class="py-5">
        <b-row>
          <b-col md="4">
            <p class="h2">我们的目标</p>
          </b-col>
          <b-col md="8">
            <p class="h1 shadowed-text">以微光照亮微光，让孩子心有所依</p>
            <br />
            <p>&emsp;&emsp;“满天星——共济平台”是一个长期关注和帮扶失依儿童心理健康成长的平台。2015年起同济大学人文学院师生共同启动满天星计划，依托同济大学高等教育平台及心理学专业人才，对阜南县登记在册的556名失依儿童的心理状况进行测评，根据评估结果进行分层次干预，运用各种形式的心理活动分类进行心理辅导。通过“请出来+走进去”的活动方式，改善当地失依儿童长期因孤独、缺乏关注和引导等原因引起的各类心理困扰。</p>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
  },
  data() {
    return {
      images: [
        require('../assets/img/index-bg-0.jpg'),
        require('../assets/img/index-bg-1.jpg'),
        require('../assets/img/index-bg-2.jpg'),
        require('../assets/img/index-bg-3.jpg'),
        require('../assets/img/index-bg-4.jpg')
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.shadowed-text {
  text-shadow: 2px 2px 2px #AAA;
  ;
}
</style>
